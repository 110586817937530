import BachLink from './BachLink';
import Icon from './Icon';
import './BachTile.css';
import PropTypes from 'prop-types';

function BachTile( props ) {
  const classnamesLink = ['p-2 shadow-sm',
    props.variant,
    props.isactive?'isactive':'',
    props.isEditable?'editable':''].join(' ');

  return (
    <li
      onClick={(e)=>{
        // console.log('click', e.target.querySelector('a'));
        if ( e.target.querySelector('a') ) {
          e.target.querySelector('a').click();
        }
      }}
      onMouseDown={props.onDragStart}
      onMouseUp={props.onDragEnd}
      onTouchStart={props.onDragStart}
      onTouchEnd={props.onDragEnd}
      className={`col-6 ${ props.isEditable?'draggable col-lg-3 col-xl-2':
        (props.editable?'col-lg-3 col-xl-2':'') }`}
      data-index={props.index}
    >
      <BachLink href={props.href} className={classnamesLink}>
        {props.title}
        {props.icon&&<Icon icon={props.icon} variant={props.variant} />}
      </BachLink>
      {props.children}
    </li>
  );
}

BachTile.propTypes={
  icon: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  href: PropTypes.string,
  isactive: PropTypes.bool,
  editable: PropTypes.bool,
  isEditable: PropTypes.bool,
  children: PropTypes.array,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  index: PropTypes.number,
};
export default BachTile;
